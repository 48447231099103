@import '../../../styles/typography';
@import '../../../styles/breakpoints';
@import '../../../styles/capitalize';
@import '../../../styles/baseline';

.typography {
  &__primary {
    @include primary();
  }

  &__uppercase {
    text-transform: uppercase;
  }

  &__capitalize {
    text-transform: capitalize;
  }

  &__strikeThrough {
    text-decoration: line-through;
  }

  &__singleLine {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  &__breakingLine {
    overflow: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;
    display: block;
  }

  &__noLineHeight {
    line-height: unset;
  }

  &__paragraph {
    max-width: 80 * $baseline;
  }
}

@mixin typographySize($name, $sizesFromBreakpoint) {
  .typography__size#{capitalize($name)} {
    @each $sizeFrom in $sizesFromBreakpoint {
      $breakpoint: map-get($sizeFrom, breakpoint);

      @include from($breakpoint) {
        $fontSize: map-get($sizeFrom, fontSize);
        $lineHeight: map-get($sizeFrom, lineHeight);

        font-size: $fontSize;
        line-height: $lineHeight;
      }
    }
  }
}

@each $size in $typographySizes {
  @include typographySize(
    map-get($size, name),
    map-get($size, sizesFromBreakpoint)
  );
}
