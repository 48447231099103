.appointmentBox {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 3px 3px 50px rgb(0, 0, 0), -2px 0 0.4em rgb(128, 77, 0);
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &__highlighted::before {
    opacity: 1;
  }
}
