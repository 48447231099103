@import '../../../styles/spacing';

@mixin elementAbsolutePosition() {
  &__absolute {
    position: absolute;
  }

  &__absolutePositionMyDimensionsToMatchParents {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__absolutePositionMyTopRightToTopRightOfParent {
    top: 0;
    right: 0;
  }

  &__absolutePositionMyTopLeftToTopLeftOfParent {
    top: 0;
    left: 0;
  }

  &__absolutePositionMyTopRightToTopLeftOfParent {
    top: 0;
    right: 100%;
  }

  &__absolutePositionMyTopLeftToTopRightOfParent {
    top: 0;
    left: 100%;
  }

  &__absolutePositionMyCenterLeftToCenterLeftOfParent {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &__absolutePositionMyCenterRightToCenterRightOfParent {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &__absolutePositionMyBottomRightToTopLeftOfParentWithSmOffset {
    bottom: calc(100% - #{$spacingSm});
    left: calc(100% - #{$spacingSm});
  }

  &__absolutePositionMyBottomLeftToTopRightOfParentWithSmOffset {
    bottom: calc(100% - #{$spacingSm});
    right: calc(100% - #{$spacingSm});
  }

  &__absolutePositionMyTopLeftOfParentWithSmOffset {
    top: 117%;
    right: 0%;
  }
}
